import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, DoBootstrap } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CoreModule } from './core/core.module';
import { ThemeModule } from './theme/theme.module';
import zh from '@angular/common/locales/zh';
import { registerLocaleData } from '@angular/common';

registerLocaleData(zh);

import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_CN } from 'ng-zorro-antd/i18n';
import { SharedModule } from './shared.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { environment } from 'src/environments/environment';
import { SecurityModule } from './security/security.module';
import { CopyrightHttpInterceptor } from './app.interceptor';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        ThemeModule,
        SharedModule,
        SecurityModule,
        StoreModule.forRoot([]),
        EffectsModule.forRoot([]),
        AppRoutingModule,
        HttpClientModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'), // Import all modules from echarts:
        }),
    ],
    providers: [
        { provide: NZ_I18N, useValue: zh_CN },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CopyrightHttpInterceptor,
            multi: true
        },
    ]
})

export class AppModule implements DoBootstrap {
  async ngDoBootstrap(app: any) {
    try {
      app.bootstrap(AppComponent);
    } catch (error) {
      console.error('authenticate init failed', error);
    }
  }
}

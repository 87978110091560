import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
/**
 * 组件
 */
import { LoginComponent } from './component/login/login.component';
import { RegisterComponent } from './component/register/register.component';

/**
 * service
 */
import { PasswordService } from './service/password.service';
import { RegisterService } from './service/register.service';
import { LoginService } from './service/login.service';
import { RouterModule } from '@angular/router';
import { SecurityRoutingModule } from './security-routes.module';
import { SSOComponent } from './component/sso/sso.component';
import { existingUsernameValidator } from './validators/existing-username-validator';
import { SharedModule } from '../shared.module';
import { MobileLoginComponent } from './component/mobile-login/mobile-login.component';
import { AccountLoginComponent } from './component/account-login/account-login.component';
import { CanProvide } from './service/authorize.guard';
import { ChangePassWordComponent } from './component/change-password/change-passowrd.component';

/**
 * 自定义管道
 */


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    SecurityRoutingModule
  ],
  declarations: [
    LoginComponent, AccountLoginComponent, MobileLoginComponent, RegisterComponent, SSOComponent,
    ChangePassWordComponent
  ],
  exports: [
    LoginComponent, RegisterComponent, SSOComponent, AccountLoginComponent, MobileLoginComponent,
    ChangePassWordComponent
  ],

  providers: [
    PasswordService, RegisterService, LoginService, CanProvide
  ]
})
export class SecurityModule {

}

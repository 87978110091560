import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SessionService } from './session/session.service';
import { ConfigureService } from './configure/configure.service';
import { FormsModule } from '@angular/forms';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { ExUploadAvatarComponent } from './component/upload-avatar/upload-avatar.component';
import { ExUploadAssetsComponent } from './component/upload-assets/upload-assets.component';
import { NzModalModule } from 'ng-zorro-antd/modal';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NzUploadModule,
        NzModalModule,
    ],
    declarations: [
        ExUploadAvatarComponent,
        ExUploadAssetsComponent
    ],
    exports: [
        ExUploadAvatarComponent,
        ExUploadAssetsComponent
    ],
    providers: [
        SessionService,
        ConfigureService
    ]
})
export class CoreModule {

}

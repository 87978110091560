<nz-header>
  <div class="logo">
    <a href='#' class='nav-link'>
      <img src="/assets/images/icon.png">
    </a>
  </div>

  <ul nz-menu [nzTheme]="'dark'"  [nzMode]="'horizontal'" style="line-height: 64px;">
    <li nz-menu-item>
        <a [routerLink]='["/copyright/registration/dashboard"]'><i nz-icon nzType="camera"></i>作品登记</a>
    </li>
    <li nz-menu-item *ngIf="canPaper">
        <a [routerLink]='["/paper/papers"]'><i nz-icon nzType="camera"></i>大学生版权征文</a>
    </li>
    
    <li class="pull-right" nz-submenu nzTitle="用户资料" nzIcon="user">
      <ul>
        <!-- <li nz-menu-item>用户中心</li> -->
        <li nz-menu-item><a [routerLink]='["/identity/bind-mobile"]'>绑定手机</a></li>
        <li nz-menu-divider></li>
        <li nz-menu-item><a [routerLink]='["/identity/change-password"]'>重置密码</a></li>
        <li nz-menu-divider></li>
        <li nz-menu-item><a [routerLink]='["/"]'> 退出系统</a></li>
        <li nz-menu-divider></li>
        <li nz-menu-item><a [routerLink]='["/"]'> V6.0.0</a></li>
      </ul>
    </li>

    <li nz-menu-item class="pull-right">
      <i nz-icon nzType="bulb" nzTheme="outline"></i>站内信息
    </li>

  </ul>


</nz-header>

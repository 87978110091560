import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SessionConfig } from 'src/app/core/session/session';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    @Input()  // 是否显示菜单
    showMenu;

    @Input() // 当showMenu=false时，显示的标题
    title: string;
    canPaper = false;

    constructor(private router: Router) {
    }

    menus: any = [
        {
            title: '版权登记',
            path: '/copyright/registration/dashboard',
            icon: 'camera'
        },
        {
            title: '大学生征文',
            path: '/paper/papers',
            icon: 'camera'
        },
        // {
        //     title: '版权资讯',
        //     path: '/content/contents',
        //     icon: 'camera'
        // },
        // {
        //     title: '版权交易',
        //     path: '/commerce',
        //     icon: 'camera'
        // },
        // {
        //     title: '作品公告',
        //     path: '/announce',
        //     icon: 'camera'
        // },
        // {
        //     title: '稿酬收转',
        //     path: '/royalty',
        //     icon: 'camera'
        // },
        // {
        //     title: '系统管理',
        //     path: '/setting',
        //     icon: 'camera'
        // },
    ];


    ngOnInit() {
        // this.authenticateService.loadUserInfo()
        // .then((userInfo)=>{
        //     const appReview = userInfo.resource_access['app-review'] || {}; 
        //     const roles = appReview.roles||[];
        //     this.canPaper = roles.filter(role => role === 'ROLE_PAPER').length !== 0;
        // })
    }

    public goPage(path: string): void {
        this.router.navigateByUrl(path);
    }
}

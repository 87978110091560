import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanProvide } from './security/service/authorize.guard';
import { LayoutComponent } from './theme/component/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/security/login',
  },
  {
    path: 'security',
    loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
  },
  {
     path: 'identity',
     component: LayoutComponent,
     loadChildren: () => import('./identity/identity.module').then(m => m.IdentityModule),
  },
  {
    path: 'copyright',
    component: LayoutComponent,
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: 'setting',
    component: LayoutComponent,
    loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { RouterModule, Routes, CanActivate } from '@angular/router';
import { NgModule } from '@angular/core';

import { LoginComponent } from './component/login/login.component';
import { RegisterComponent } from './component/register/register.component';
import { SSOComponent } from './component/sso/sso.component';
import { ChangePassWordComponent } from './component/change-password/change-passowrd.component';


const ROUTES: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'change-password',
        component: ChangePassWordComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'session/:sessionId',
        component: SSOComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(ROUTES)
    ],
    exports: [
        RouterModule
    ]
})

export class SecurityRoutingModule {

}



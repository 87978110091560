import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { SessionConfig } from "src/app/core/session/session";

@Injectable()
export class CanProvide implements CanActivate {
    
    constructor(private router: Router) {};

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const token = JSON.parse(window.sessionStorage.getItem(SessionConfig.token_key));
        const user = JSON.parse(window.sessionStorage.getItem(SessionConfig.user_key));
        if (token != null && user != null) {
            return Promise.resolve(true)
        } else {
            var path = route.routeConfig.path;
            console.log(path);
            if (path != "/security/login") {
                this.router.navigateByUrl("/security/login");
            } else {
                return Promise.resolve(true)
            }
        }
    }

}
  <div class="login-html">
    <form nz-form class="login-form">
      <div nz-form-item *ngIf='!success'>
        <div nz-form-control>
          <nz-input-group nzSize="large" [nzAddOnAfter]="suffixButton">
            <input type="text" [(ngModel)]='phone' name="phone" nz-input placeholder="请输入手机号" />
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button (click)="getCaptcha" nzType="primary" nzSize="large" nzSearch>获取验证码</button>
          </ng-template>
        </div>
      </div>

      <div nz-form-item *ngIf='!success'>
        <div nz-form-control>
          <input type="text" [(ngModel)]='captcha' name='captcha' nz-input placeholder="请输入你收到的验证码" />
        </div>
      </div>
      
    </form>
  </div>


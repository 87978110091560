<div>
  <nz-upload [nzHeaders]="uploadHeader" nzAction="{{ uploadAction }}" nzListType="picture-card"
    [(nzFileList)]="fileList" [nzShowUploadList]="showUploadList" [nzPreview]="handlePreview" (nzChange)="handleChange($event)">
    <i nz-icon nzType="plus"></i>
    <div class="ant-upload-text">上传</div>  
  </nz-upload>

  <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null" (nzOnCancel)="previewVisible = false">
        <ng-template #modalContent>
          <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
        </ng-template>
  </nz-modal>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ConfigureService } from '../../core/configure/configure.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class LoginService {

    constructor(private httpclient: HttpClient,
                private router: Router,
                private config: ConfigureService) {
    }

    login(data): Observable<any> {
        const url = this.config.get('identity') + '/token';
        return this.httpclient.post(url, {grant_type: 'password', ...data});
    }

    loginWithSession(sessionId): Observable<any> {
        const url = this.config.get('identity') + '/session';
        let params = new HttpParams();
        params = params.set('sessionId', sessionId);
        return this.httpclient.get(url, { params } );
    }

    logout(token: string): Observable<any> {
        return new Observable(observer => {
            observer.next(null);
        });
    }
}

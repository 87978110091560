import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

    isCollapsed = false;

    constructor(private router: Router, private routerIonfo: ActivatedRoute) {
    }

    /**
   * 初始化
   */
    ngOnInit() {

        // // 页面监听
        // Observable.fromEvent(window, 'resize')
        //     .debounceTime(100) // 以免频繁处理
        //     .subscribe((event) => {
        //         // 这里处理页面变化时的操作
        //         this.isCollapsed = window.innerWidth < 768 ? true : false;
        //     });
    }
}

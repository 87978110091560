import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {

    isCollapsed = false;
    triggerTemplate = null;
    customTrigger: TemplateRef<void>;
    constructor(private router: Router) {

    }

    menuItems: any = [

    ];

    ngOnInit() {

    }

    showMenuItem(menuItem): boolean {
        // if (menuItem.permissionName) {
        //     return this.permission.isGranted(menuItem.permissionName);
        // }

        return true;
    }

    changeTrigger(): void {
      this.triggerTemplate = this.customTrigger;
    }

    public goPage(path: string): void {
        this.router.navigateByUrl(path);
    }
}

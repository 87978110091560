<div class="register-wrap">
  <div class="register-html">

    <form nz-form [formGroup]="userForm" class="register-form">
      <nz-form-item nz-row>
        <nz-form-label nz-col [nzSm]='6' nzRequired>
          用户名称
        </nz-form-label>
        <nz-form-control nz-col [nzSm]='18' nzHasFeedback nzErrorTip="用户名称已经存在！或已经注册">
          <input nz-input formControlName='username' required placeHolder="5-50个字符, 推荐使用手机号码或邮箱" nzSize="large">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-row>
        <nz-form-label nz-col [nzSm]='6' nzRequired>
          <label for="password"> <i class="fa fa-unlock-alt" aria-hidden="true"></i>登录密码</label>
        </nz-form-label>
        <nz-form-control nz-col [nzSm]='18' nzHasFeedback>
          <input nz-input formControlName='password' type="password" placeHolder="只能输入6-20个字母、数字、下划线"
            nzSize="large">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-row>
        <nz-form-label nz-col [nzSm]='6' nzRequired>
          <label for="confirmPass">
            <i class="fa fa-check-circle" aria-hidden="true"></i>确认密码</label>
        </nz-form-label>
        <nz-form-control nz-col [nzSm]='18' nzHasFeedback nzErrorTip="确认密码与登陆密码不一致！">
          <input nz-input formControlName='confirmPass' type="password" placeHolder="请输入登陆密码" nzSize="large">
        </nz-form-control>
      </nz-form-item>
      <!--
      <nz-form-item nz-row>
        <nz-form-label nz-col [nzSm]='6'>
          <label for="email">
            <i class="fa fa-envelope" aria-hidden="true"></i>邮箱地址</label>
        </nz-form-label>
        <nz-form-control nz-col [nzSm]='18'>
          <input nz-input formControlName='email' type="email" placeHolder="绑定邮箱，便于发送邮件联系" nzSize="large">
        </nz-form-control>
      </nz-form-item>
      -->
      <nz-form-item nz-row>
        <nz-form-label nz-col [nzSm]='6' nzRequired>
          <label for="mobile">
            <i class="fa fa-mobile" aria-hidden="true"></i>手机号码</label>
        </nz-form-label>
        <nz-form-control nz-col [nzSm]='18' nzHasFeedback nzErrorTip="请输入手机号码！">
          <input nz-input formControlName='mobile' type="text" placeHolder="绑定手机号，可以找回账户、密码" nzSize="large">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="margin-top: 40px;" nz-row>
        <div nz-form-control nz-col [nzSm]='24'>
          <button nz-button [disabled]="!userForm.valid" class="register-form-button" nzType="primary" nzSize="large" (click)='register()'>注册</button>
          <div style="text-align:center;">
            <span>已有账户，点击</span>
            <a [routerLink]="['/security/login']"> 立即登录</a>
          </div>
        </div>
      </nz-form-item>
    </form>
  </div>
</div>

import { Component, OnInit, forwardRef } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigureService } from '../../configure/configure.service';
// import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'ex-upload-assets',
  templateUrl: './upload-assets.component.html',
  styleUrls: ['./upload-assets.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ExUploadAssetsComponent),
    multi: true,
  }],
})
/**
 * 上传头像
 */
export class ExUploadAssetsComponent implements OnInit , ControlValueAccessor {

  uploadAction: string;
  // 图片ID
  private innerValue: string[] = [];
  private onChangeCallback: (_: any) => void = function (_) { };

  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true
  };

  fileList = [];
  previewImage: string | undefined = '';
  previewVisible = false;

  constructor(
    // private tokenService: KeycloakService,
    private configureService: ConfigureService) { }

  ngOnInit() {
    this.uploadAction = this.configureService.get('asset') + '/asset/upload';
  }

  uploadHeader =  () => {
    return {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('__my-session-token__'),
      'X-TENANT-ID': 'master'
    };
  }

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        break;
      case 'done':
        const ids = this.value || [];
        ids.push(info.file.response.id);
        this.value = ids;
        break;
      case 'error':
        break;
    }
  }

  handlePreview = (file: NzUploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
  };



  get value():string[] {
    return this.innerValue;
  }

  set value(v: string[]) {
      this.innerValue = v;
      this.onChangeCallback(v);
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value||[];
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    
  }
  
}


/** 登录后返回的Session */
export interface Session {
    userCode: string;
    userName: string;
    token: string;
    groups: any;
}

// session配置
export const SessionConfig = {
    // session在浏览器的storerage中的存储key
    group_key: '__my-session-group__',
    user_key: '__my-session-user__',
    token_key: '__my-session-token__',
    role_key: '__my-session-role__',
    query_accept_key: '__my-session-accept__',
    query_verify_key: '__my-session-verify__',
    query_review_key: '__my-session-review__',
    query_revoke_key: '__my-session-revoke__',
    query_alter_key: '__my-session-alter__',
    
    current_key: '__my-session-current__',
};

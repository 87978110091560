import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PasswordService } from '../../service/password.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as CryptoJS from 'crypto-js';  

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'lib-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})

/**
 * 找回密码
 */
export class ChangePassWordComponent implements OnInit {
    key: string = CryptoJS.enc.Utf8.parse("1234567890000000");
    iv: string = CryptoJS.enc.Utf8.parse("1234567890000000");
    validateForm: FormGroup;
    validate: boolean = false;
    canGetCaptcha: boolean;
    user: string;
    timer;
    count: number;

    constructor(private router: Router,
                private fb: FormBuilder,
                private passwordService: PasswordService,
                private notification: NzNotificationService) {
    }

    ngOnInit() {
        this.validateForm = this.fb.group({
            mobile: [null, [Validators.required]],
            captcha: [null, [Validators.required]],
            password: [null],
            repeat: [null],
        });
        this.validate = false;
        this.canGetCaptcha = true;
    }

    getCaptcha(event) {
        const param = {
            mobile: this.validateForm.get('mobile').value,
            captcha: this.validateForm.get('captcha').value,
        };

        if(param.mobile == null) {
            this.notification.warning('警告', "请填写手机号码！");
        }

        this.passwordService.getCaptcha(param.mobile).subscribe({
            next:(response) => {
                // 定时器
                this.canGetCaptcha = false;
                this.user = response.user;
                this.count = 60;
                this.timer = setInterval(() => {
                    this.count = this.count - 1;   
                    if(this.count < 1) {
                        clearInterval(this.timer);
                        this.canGetCaptcha = true;
                    } 
                }, 1000)
            },
            error:(error) => {
                this.canGetCaptcha = true;
                this.notification.warning('警告', error.error.detail);
            }
        });
    }

    validateCaptcha(event) {
        const param = {
            mobile: this.validateForm.get('mobile').value,
            captcha: this.validateForm.get('captcha').value,
            locate: this.user
        };

        this.passwordService.validateCaptcha(param).subscribe({
            next:(response) => {
                // 验证码正确，重置密码
                this.validate = true;
            },
            error:(error) => {
                this.validate = false;
                this.notification.warning('警告', '验证码不一致，请重新输入！');
            }
        });
    }

    encrypt(txt: string): string {
        console.log(txt);
        let encrypted =  CryptoJS.AES.encrypt(txt, 
            this.key, 
            {
                iv: this.iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.ZeroPadding
            }
        );
        
        return encrypted.toString();
    }

    changePassword(event) {
        const param = {
            // mobile: CryptoJS.AES.encrypt(this.validateForm.get('mobile').value.trim(), this.key),
            captcha: this.encrypt(this.validateForm.get('captcha').value.trim()),
            password: this.encrypt(this.validateForm.get('password').value.trim()),
            repeat: this.encrypt(this.validateForm.get('repeat').value.trim()),
            user: this.encrypt(this.user.trim()),
            locate: this.encrypt(this.user.trim()),
        };
        
        this.passwordService.changePassword(param).subscribe({
            next:(response) => {
                this.notification.info('提示', '密码重置成功！');
                // 延迟调整登陆 
                this.router.navigateByUrl('/security/login');
            },
            error:(error) => {
                console.log(error);
                this.notification.warning('警告', '验证码不一致，请重新输入！');
            }
        });
    }

}

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LayoutComponent } from './component/layout/layout.component';
import { HeaderComponent } from './component/header/header.component';
import { HeaderUserComponent } from './component/header/header-user.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { SharedModule } from '../shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        SharedModule,
    ],
    declarations: [
        LayoutComponent, HeaderComponent, HeaderUserComponent, SidebarComponent,
    ],
    exports: [
        LayoutComponent, HeaderComponent, HeaderUserComponent, SidebarComponent,
    ],
    providers: [
    ]
})

export class ThemeModule {

}

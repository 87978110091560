import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../../service/login.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SessionService } from '../../../core/session/session.service';
import { SessionConfig } from 'src/app/core/session/session';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'lib-sso',
    templateUrl: './sso.component.html',
    styleUrls: ['./sso.component.css'],
})

export class SSOComponent implements OnInit {

    sessionId: string;
    logining = true;

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private notification: NzNotificationService,
                private loginService: LoginService,
                private sessionService: SessionService) {
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.sessionId = params.sessionId;
            console.log(this.sessionId);
            this.login();
        });
    }

    login() {

        window.sessionStorage.removeItem(SessionConfig.group_key);
        window.sessionStorage.removeItem(SessionConfig.role_key);
        window.sessionStorage.removeItem(SessionConfig.token_key);
        window.sessionStorage.removeItem(SessionConfig.user_key);

        this.loginService.loginWithSession(this.sessionId).subscribe(
            result => {
                this.logining = false;
                window.sessionStorage.setItem(SessionConfig.group_key, JSON.stringify(result.user.groups));
                window.sessionStorage.setItem(SessionConfig.role_key, JSON.stringify(result.user.roles));
                window.sessionStorage.setItem(SessionConfig.token_key, JSON.stringify(result.token));
                window.sessionStorage.setItem(SessionConfig.user_key, JSON.stringify(result.user));
                // 跳转页面
                this.router.navigateByUrl('/copyright/registration/dashboard');
            },
            error => {
                this.logining = false;
                console.log(error);
                this.router.navigateByUrl('/security/login');
            });
    }
}

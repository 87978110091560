import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ConfigureService {

    private configure: any = {
        registration: 'https://api.sc-copyright.org/copyright/v1',
        certificate: 'https://api.sc-copyright.org/copyright/v1',
        asset: 'https://api.sc-copyright.org/copyright/v1',
        copyright: 'https://api.sc-copyright.org/copyright/v1',
        identity: 'https://api.sc-copyright.org',
        content: 'https://api.sc-copyright.org/copyright/v1'
    };
    private url = 'assets/json/configure.json';

    constructor(private http: HttpClient) {

    }

    public init() {
        this.http.get(this.url).subscribe(
            (response) => {
                this.configure = response;
            }
        );
    }

    public set(key: string, value: string): void {
        this.configure[key] = value;
    }

    public get(key: string): string {
        return this.configure[key] || false;
    }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../../service/login.service';
import { SessionService } from '../../../core/session/session.service';
import { SessionConfig } from 'src/app/core/session/session';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'lib-account-login',
    templateUrl: './account-login.component.html',
    styleUrls: ['./account-login.component.css'],
})

export class AccountLoginComponent implements OnInit {

    userForm: FormGroup;
    logining = true;

    constructor(private fb: FormBuilder,
                private router: Router,
                private notification: NzNotificationService,
                private loginService: LoginService,
                private sessionService: SessionService) {
    }

    ngOnInit() {
        // 能自动登陆
        this.userForm = this.fb.group({
            userName: [null, [Validators.required]],
            password: [null, [Validators.required]],
            remember: [true],
        });
        window.sessionStorage.removeItem(SessionConfig.group_key);
        window.sessionStorage.removeItem(SessionConfig.role_key);
        window.sessionStorage.removeItem(SessionConfig.token_key);
        window.sessionStorage.removeItem(SessionConfig.user_key);
    }

    close() {

    }

    _submitUserForm() {

        if (this.userForm.invalid) {
            return;
        }

        const loginData = {
            username: this.userForm.get('userName').value,
            password: this.userForm.get('password').value
        };

        window.sessionStorage.removeItem(SessionConfig.group_key);
        window.sessionStorage.removeItem(SessionConfig.role_key);
        window.sessionStorage.removeItem(SessionConfig.token_key);
        window.sessionStorage.removeItem(SessionConfig.user_key);
        window.sessionStorage.removeItem(SessionConfig.query_accept_key);
        window.sessionStorage.removeItem(SessionConfig.query_review_key);
        window.sessionStorage.removeItem(SessionConfig.query_verify_key);
        
        this.loginService.login(loginData).subscribe(
            result => {
                this.logining = false;
                window.sessionStorage.setItem(SessionConfig.group_key, JSON.stringify(result.user.groups));
                window.sessionStorage.setItem(SessionConfig.role_key, JSON.stringify(result.user.roles));
                window.sessionStorage.setItem(SessionConfig.token_key, JSON.stringify(result.token));
                window.sessionStorage.setItem(SessionConfig.user_key, JSON.stringify(result.user));
                // 跳转页面
                this.router.navigateByUrl('/copyright/registration/dashboard');
            },
            error => {
                this.logining = false;
                this.notification.error('登录失败', '账号或密码错误，请重新输入！');
            });
    }
}

<div class="login-wrap">
  <nz-tabset>
    <nz-tab nzTitle="账户登陆">
      <lib-account-login></lib-account-login>
    </nz-tab>
    <!-- <nz-tab nzTitle="手机登陆">
      <lib-mobile-login></lib-mobile-login>
    </nz-tab> -->
  </nz-tabset>
  
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ConfigureService } from '../../core/configure/configure.service';

@Injectable()
export class RegisterService {

    constructor(private httpclient: HttpClient, private router: Router,
                private config: ConfigureService) {
    }

    register(params): Observable<any> {
        const url = this.config.get('copyright') + '/users';

        return this.httpclient.post(url, params);
    }

    exists(username): Promise<any> {
        const url = this.config.get('copyright') + '/users/' + username + '/exists';

        return this.httpclient.get(url).toPromise();
    }
}

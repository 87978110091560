import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionConfig } from './core/session/session';

@Injectable()
export class CopyrightHttpInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = window.sessionStorage.getItem(SessionConfig.token_key);
        const JWT = `Bearer ${token}`;
        const authReq = req.clone(
            {
                // FIXME 使用配置的的租户
                headers: req.headers
                            // .set('X-TENANT-ID', 'copyright')
                            .set('Authorization', JWT)
                
            }
        );
        return next.handle(authReq);
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PasswordService } from '../../service/password.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'lib-mobile-login',
    templateUrl: './mobile-login.component.html',
    styleUrls: ['./mobile-login.component.css']
})

export class MobileLoginComponent implements OnInit {

    public success = false;
    public username: any;
    public password: any;
    public phone: any;
    public repeat: any;
    public captcha: string;
    public validateMessage: any;
    public data: any = {};
    public flag = false;

    constructor(private router: Router,
                private passwordService: PasswordService,
                private notification: NzNotificationService) {
    }

    ngOnInit() {
    }

    getCaptcha() {
        const param = {
            password: this.password,
        };

        this.passwordService.changePassword(this.data).subscribe(
            response => {
                this.router.navigate(['/']);
            },
            error => {
                this.notification.error('失败', '修改失败');
            }
        );
    }

    modifyPassWord() {
        const param = {
            password: this.password,
            repeat: this.repeat
        };

        this.passwordService.changePassword(this.data).subscribe(
            response => {
                this.router.navigate(['/']);
            },
            error => {
                this.notification.error('失败', '修改失败');
            }
        );
    }

    getValidateMessage() {

        const param = {
            username: this.username,
            mobile: this.phone,
        };
    }

    checkValidateMessage() {
        if (this.data && this.validateMessage && this.data.code === this.validateMessage) {
            this.flag = true;
        } else {
            this.flag = false;
        }
    }

    goModifyPass() {
        if (this.flag) {
            this.success = true;
        } else {
            this.notification.warning('警告', '验证码不一致，请重新输入！');
        }
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigureService } from '../../core/configure/configure.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PasswordService {

    constructor(private httpclient: HttpClient, private config: ConfigureService) {

    }

    changePassword(data): Observable<any> {
        const url = this.config.get('identity') + '/token/change-password';

        return this.httpclient.patch(url, data);
    }

    getCaptcha(mobile): Observable<any> {
        const url = this.config.get('identity') + '/token/captcha';

        return this.httpclient.post(url, {mobile: mobile});
    }

    validateCaptcha(data): Observable<any> {
        const url = this.config.get('identity') + '/token/validate-captcha';

        return this.httpclient.post(url, data);
    }

}

import { Component, OnInit, Input } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SessionService } from 'src/app/core/session/session.service';
import { Router } from '@angular/router';
import { SessionConfig } from 'src/app/core/session/session';

@Component({
    selector: 'app-header-user',
    template: `
        <a nz-dropdown  nzTrigger="click" style="float:right;">
            <a class="ant-dropdown-link" nz-dropdown>个人中心<i class="anticon anticon-down"></i></a>
            <ul nz-menu [nzTheme]="'dark'">
                <li nz-menu-item><a (click)="doLogout()">安全退出</a></li>
            </ul>
        </a>
    `,
    styles: [`
        .welcome{
            margin-right:5px;
            color:rgba(255,255,255,0.67)
        }
        li{
            background-color: #1797be;
        }
        .ant-dropdown-link{
            display: block;
            height: 100%;
        }
    `]
})
export class HeaderUserComponent implements OnInit {

    constructor(
        private router: Router,
        private confirmServ: NzModalService,
        private sessionService: SessionService) {

    }

    ngOnInit() {
    }

    logout(): void {
        // FIXME
        // this.confirmServ.confirm({
        //     title: '您是否确认要退出系统？',
        //     onOk: () => this.doLogout(),
        //     onCancel() { }
        // });
    }

    setAccount(): void {
        // this.router.navigate(['/copyright/organization/users/view', this.sessionService.get(SessionConfig)]);
    }


    modifyPassWord() {
        this.router.navigateByUrl('/identity/modifypass');
    }

    doLogout(): void {
        window.sessionStorage.removeItem(SessionConfig.group_key);
        window.sessionStorage.removeItem(SessionConfig.role_key);
        window.sessionStorage.removeItem(SessionConfig.token_key);
        this.router.navigateByUrl('/security/login');
    }
}

<div class="validate-wrap">
  <div class="validate-html">
    <form nz-form class="validate-form" [formGroup]="validateForm">
      <nz-form-item *ngIf="!validate">
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="mobile" nzRequired>
          手机号
        </nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请填写手机号码!">
          <input nz-input formControlName="mobile" placeHolder="'请填写手机号'" nzSize="large">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item  *ngIf="!validate">
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>
          验证码
        </nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入您获取的验证码!">
          <div nz-row [nzGutter]="8">
            <div nz-col [nzSpan]="12">
              <input nz-input formControlName="captcha" id="captcha" />
            </div>
            <div nz-col [nzSpan]="12">
              <button nz-button [disabled]='!canGetCaptcha' (click)="getCaptcha($event)"><p *ngIf="canGetCaptcha">获取验证码</p><p *ngIf="!canGetCaptcha">{{count}}</p></button>
            </div>
          </div>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="validate">
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>
          新密码
        </nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请设置新密码!">
          <input nz-input formControlName="password" placeHolder="'请设置新密码'" nzSize="large">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="validate">
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>
          重复密码
        </nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请重复输入密码!">
          <input nz-input formControlName="repeat" placeHolder="'请重复输入密码'" nzSize="large">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-row class="register-area">
        <nz-form-control [nzSpan]="14" [nzOffset]="6">
          <button nz-button nzType="primary" (click)="validateCaptcha($event)" *ngIf="!validate">找回密码</button>
          <button nz-button nzType="primary" *ngIf="validate" (click)="changePassword($event)">重置密码</button>
          <a [routerLink]="['/security/login']" class="login"> 立即登录</a>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>

import { Component, OnInit, forwardRef } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigureService } from '../../configure/configure.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ex-upload-avatar',
  templateUrl: './upload-avatar.component.html',
  styleUrls: ['./upload-avatar.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ExUploadAvatarComponent),
    multi: true,
  }],
})
/**
 * 上传头像
 */
export class ExUploadAvatarComponent implements OnInit, ControlValueAccessor {

  uploadAction: string;
  loading = false;
  url: string;
  // 图片ID
  private innerValue: any = '';
  private onChangeCallback: (_: any) => void = function() { };

  constructor(private configureService: ConfigureService) { }

  ngOnInit(): void {

  }

  getUploadAction = (file: NzUploadFile) => {
    return  this.configureService.get('asset') + '/asset/upload';
  }

  uploadHeader =  () => {
    return {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('__my-session-token__'),
      'X-TENANT-ID': 'master'
    };
  }

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.loading = false;
        this.value = info.file.response.id;
        this.url = info.file.response.url;
        break;
      case 'error':
        this.loading = false;
        break;
    }
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {

  }

}

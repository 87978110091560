import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../model/user.model';
import { RegisterService } from '../../service/register.service';
import { LoginService } from '../../service/login.service';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SessionService } from '../../../core/session/session.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SessionConfig } from 'src/app/core/session/session';
import { MustMatch } from '../../validators/must-match-validator';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'lib-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

    private user: User;
    userForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        private registerService: RegisterService,
        private loginService: LoginService,
        private session: SessionService,
        private router: Router,
        private notification: NzNotificationService) { }

    ngOnInit() {
        this.userForm = this.fb.group({
            username: [null, [Validators.required]],
            // , existingUsernameValidator(this.registerService)
            password: [null, [Validators.required]],
            confirmPass: [null, [Validators.required]],
            email: [null],
            mobile: [null, [Validators.required]],
        },
        {
            validator: MustMatch('password', 'confirmPass')
        });
    }

    get f() { return this.userForm.controls; }

    public register() {
        if (this.userForm.invalid) {
            return;
        }
        const params = {
            name: this.userForm.controls.username.value,
            email: this.userForm.controls.email.value,
            mobile: this.userForm.controls.mobile.value,
            password: this.userForm.controls.password.value
        };

        this.registerService.register(params).subscribe(res => {
            const user = {
                password: res.user.password,
                username: res.user.name,
            };
            this.loginService.login(user).subscribe(result => {
                // 加入本地缓存
                window.sessionStorage.setItem(SessionConfig.group_key, JSON.stringify(result.user.groups));
                window.sessionStorage.setItem(SessionConfig.role_key, JSON.stringify(result.user.roles));
                window.sessionStorage.setItem(SessionConfig.token_key, JSON.stringify(result.token));
                window.sessionStorage.setItem(SessionConfig.user_key, JSON.stringify(result.user));
                this.notification.success('注册成功！', '');
                this.router.navigateByUrl('/copyright/registration/historic/register');
            });
        },
        error => {
            this.notification.error('注册失败！', error.error);
        });
    }

    checkName() {
        const patrn = /^[a-zA-Z\u4e00-\u9fa5]{2,20}$/;
        if (!this.user.username || !patrn.exec(this.user.username)) {
            this.user.usernameError = '用户名错误，只能输入2-20个字符';
            return false;
        } else {
            this.user.usernameError = '';
            return true;
        }
    }

    checkPassword() {
        const patrn = /^(\w){6,20}$/;
        if (!this.user.password || !patrn.exec(this.user.password)) {
            this.user.passwordError = '密码错误，只能输入6-20个字母、数字、下划线';
            return false;
        } else {
            this.user.passwordError = '';
            return true;
        }
    }

    checkConfirmPass() {
        if (!this.user.password || !this.user.confirmPass || this.user.password !== this.user.confirmPass) {
            this.user.confirmPassError = '两次输入密码不一致，请重新输入';
            return false;
        } else {
            this.user.confirmPassError = '';
            return true;
        }
    }

    checkMobile() {
        const patrn = /^1(3|4|5|7|8)\d{9}$/;
        if (!this.user.mobile || !patrn.exec(this.user.mobile)) {
            this.user.mobileError = '手机号错误';
            return false;
        } else {
            this.user.mobileError = '';
            return true;
        }
    }

    checkEmail() {
        const patrn = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (!this.user.email || !patrn.exec(this.user.email)) {
            this.user.emailError = '邮箱错误';
            return false;
        } else {
            this.user.emailError = '';
            return true;
        }
    }
}

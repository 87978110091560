import { Component, OnInit } from '@angular/core';
import {SessionService} from './core/session/session.service'
import {SessionConfig} from './core/session/session'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  
  title = 'copyright';

  constructor(
    private sessionService:SessionService,
    ){

  }

  ngOnInit(): void {
   

  }
}

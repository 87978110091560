  <div class="login-html">
    <form nz-form [formGroup]="userForm" class="login-form">
      <div nz-form-item>
        <div nz-form-label>
          <label for="userName"><i class="fa fa-user-circle" aria-hidden="true"></i>用户名称</label>
        </div>
        <div nz-form-control>
          <input nz-input formControlName="userName" placeHolder="请输入账户" nzSize="large">
          <div nz-form-explain *ngIf="userForm.get('userName').dirty&&userForm.get('userName').errors">请输入账户!</div>
        </div>
      </div>
      <div nz-form-item>
        <div nz-form-label>
          <label for="password">
            <i class="fa fa-unlock-alt" aria-hidden="true"></i>登录密码</label>
        </div>
        <div nz-form-control>
          <input nz-input formControlName="password" type="password" placeHolder="请输入密码" nzSize="large">
          <div nz-form-explain *ngIf="userForm.get('password').dirty&&userForm.get('password').errors">请输入密码!</div>
        </div>
      </div>

      <div nz-form-item>
        <button nz-button class="login-form-button" type="'primary'" (click)="_submitUserForm()" nzSize="large">登录</button>
      </div>
      
      <div nz-form-item nz-row style="margin-top: 20px">
        <nz-form-control [nzSpan]="24">
          <a [routerLink]="['/security/register']">注册新用户</a>
          <a [routerLink]="['/security/change-password']" style="margin-left:40px;">找回密码</a>
        </nz-form-control>
      </div>
      
    </form>
  </div>

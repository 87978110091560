import { Injectable } from '@angular/core';
import { SessionConfig } from './session';

@Injectable({
    providedIn: 'root',
})
export class SessionService {

    set(key: string, value: any) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    }

    get(key: string) {
        return JSON.parse(window.sessionStorage.getItem(key));
    }

    remove(key) {
        window.sessionStorage.removeItem(key);
    }

    getUser() {
        return this.get(SessionConfig.user_key);
    }

    getRole() {
        return this.get(SessionConfig.role_key);
    }

    getGroup() {
        return this.get(SessionConfig.group_key);
    }


}

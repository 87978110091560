// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const authenticateConfig = {
  url: 'https://identity_.sc-copyright.org/auth/',
  realm: 'master',
  clientId: 'app-review'
};

export const environment = {
  production: false,
  authenticateConfig
};
